<template>
  <div id="app">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-3"
      mode="horizontal"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
    >
      <div style="left: 40px;position:absolute;margin-top: 10px;">
        <a href="/">
          <img alt="苏州金协机电科技有限公司" src="../src/assets/icon-a.png" width="68px" height="60px">
        </a>
      </div>
      <div style="left: 110px;position:absolute;margin-top: 10px;">
        <a href="/">
          <img alt="苏州金协机电科技有限公司" src="../src/assets/icon-font.png" width="486px" height="53px">
        </a>
      </div>
      <div style="left: 650px;position:absolute;margin-top: 15px;">
        <a href="#/contact">
          <el-button style="color: white;float: left;font-size: 19px;" type="text" icon="el-icon-phone"
                     @click="scrollToTopSon"><i>13255186630ㅤ</i></el-button>
          <el-button style="color: white;float: left;font-size: 19px;" type="text" icon="el-icon-message"
                     @click="scrollToTopSon"><i>yifan9609@163.com</i></el-button>
        </a>
      </div>
      <el-menu-item style="margin-top: 18px;float: right;width: 120px;font-size: 18px">
        <a href="http://www.szjinxiejd.com:81" target="_blank" style="text-decoration: none;color: white;"><b>管理系统</b></a>
      </el-menu-item>
      <el-menu-item index="/contact" style="margin-top: 20px;float: right;width: 120px;font-size: 18px"><b>联系我们</b>
      </el-menu-item>
      <el-menu-item index="/recruit" style="margin-top: 20px;float: right;width: 120px;font-size: 18px"><b>招贤纳士</b>
      </el-menu-item>
      <el-menu-item index="/equipment" style="margin-top: 20px;float: right;width: 120px;font-size: 18px"><b>设备展示</b>
      </el-menu-item>
      <el-menu-item index="/product" style="margin-top: 20px;float: right;width: 120px;font-size: 18px"><b>产品展示</b>
      </el-menu-item>
      <el-menu-item index="/goIn" style="margin-top: 20px;float: right;width: 120px;font-size: 18px"><b>走进金协</b>
      </el-menu-item>
      <el-menu-item index="/" style="margin-top: 20px;float: right;width: 120px;font-size: 18px"><b>网站首页</b>
      </el-menu-item>
    </el-menu>
    <img v-if="this.$route.path !== '/'" style="align-content: center" alt="Vue logo" src="../src/assets/bj.jpg"
         width="100%" height="200px">
    <router-view/>
    <footer style="height: 260px;background: rgb(84, 92, 100);color: white;width: 1903px">
      <div style="margin-left: 200px;margin-top: 80px">
        <br>
        <br>
        <br>
        <div style="float: left;font-size: 18px">
          <b>
            <a href="/" style="text-decoration: none;color: white;">网站首页</a>
            <a href="#/goIn" style="margin-left: 20px;text-decoration: none;color: white;"
               @click="scrollToTopSon">走进金协</a>
            <a href="#/product" style="margin-left: 20px;text-decoration: none;color: white;" @click="scrollToTopSon">产品展示</a>
            <a href="#/equipment" style="margin-left: 20px;text-decoration: none;color: white;"
               @click="scrollToTopSon">设备展示</a>
            <a href="#/recruit" style="margin-left: 20px;text-decoration: none;color: white;" @click="scrollToTopSon">招贤纳士</a>
            <a href="#/contact" style="margin-left: 20px;text-decoration: none;color: white;" @click="scrollToTopSon">联系我们</a>
            <a href="#/leaveMessage" style="margin-left: 20px;text-decoration: none;color: white;"
               @click="scrollToTopSon">在线留言</a>
            <a href="http://www.szjinxiejd.com:81" target="_blank" style="margin-left: 20px;text-decoration: none;color: white;">管理系统</a>
            <hr style="margin-top: 30px">
          </b>
          <div style="font-size: 14px;">
            <br>
            <tr style="float: left">服务热线：13255186630ㅤ易经理</tr>
            <br>
            <br>
            <tr>© 2023 苏州金协机电科技有限公司 All rights reserved</tr>
            <p><a href="https://beian.miit.gov.cn/" target="_blank"
                  style="text-decoration: none;color: white;float: left">苏ICP备2023046171号-1</a>
            </p>
            <br>
            <p>
              <img src="//g.csdnimg.cn/common/csdn-footer/images/badge.png" width="14px" style="float: left" >
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050602013274" target="_blank"
                 style="text-decoration: none;color: white;float: left"> 苏公网安备32050602013274号</a>
            </p>
          </div>
        </div>
        <div style="margin-left: 900px;font-size: 18px;width: 250px">
          <tr style="float: left;margin-top: 15px"><b>苏州金协机电科技有限公司</b></tr>
          <tr style="float: left;margin-top: 10px">邮箱：yifan9609@163.com</tr>
          <tr style="float: left">电话：13255186630</tr>
          <tr style="float: left">网址：www.szjinxiejd.com</tr>
          <a
            href="https://map.baidu.com/search/%E6%B1%9F%E8%8B%8F%E7%9C%81%E8%8B%8F%E5%B7%9E%E5%B8%82%E5%90%B4%E4%B8%AD%E5%8C%BA%E6%9C%A8%E6%B8%8E%E9%95%87%E6%9C%A8%E4%B8%9C%E8%B7%AF399%E5%8F%B7%E5%B0%A7%E5%B3%B0%E5%B7%A5%E4%B8%9A%E5%9B%AD9%E6%A0%8B/@13417883.885,3639605.2,19z?querytype=s&da_src=shareurl&wd=%E6%B1%9F%E8%8B%8F%E7%9C%81%E8%8B%8F%E5%B7%9E%E5%B8%82%E5%90%B4%E4%B8%AD%E5%8C%BA%E6%9C%A8%E6%B8%8E%E9%95%87%E6%9C%A8%E4%B8%9C%E8%B7%AF399%E5%8F%B7%E5%B0%A7%E5%B3%B0%E5%B7%A5%E4%B8%9A%E5%9B%AD9%E6%A0%8B&c=224&src=0&pn=0&sug=0&l=19&b=(13417303.125,3639476.75;13418263.125,3639953.25)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1"
            style="margin-left: 20px;text-decoration: none;color: white;"
            target="_blank"
          >
            <tr style="float: left">地址：江苏省苏州市吴中区木</tr>
            <tr style="float: left">ㅤㅤㅤ渎镇木东路399号9栋</tr>
          </a>
          <div style="margin-left: 350px;">
            <img style="width: 150px;height: 150px" src="../src/assets/qrcode.png">
          </div>
        </div>
      </div>
    </footer>
    <div>
      <a href="#/contact">
        <el-button class="scroll-top" icon="el-icon-service" @click="scrollToTopSon"></el-button>
      </a>
      <a href="#/leaveMessage">
        <el-button class="scroll-top2" icon="el-icon-chat-dot-square" @click="scrollToTopSon"></el-button>
      </a>
      <el-button class="scroll-top3" icon="el-icon-arrow-up" @click="scrollToTop"></el-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeIndex: '/',
      };
    },
    methods: {
      scrollToTopSon() {
        // 滚动到页面顶部
        window.scrollTo({
          top: 290,
          behavior: 'smooth'
        })
      },
      scrollToTop() {
        // 滚动到页面顶部
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    watch: {
      $route(to, from) {
        this.activeIndex = to.path
      }
    },

  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    margin: 0 auto;
    width: 1903px;
  }

  .el-menu-3 {
    height: 80px;
  }

  .scroll-top {
    position: fixed;
    right: 20px;
    bottom: 180px;
    z-index: 999;
  }

  .scroll-top2 {
    position: fixed;
    right: 20px;
    bottom: 140px;
    z-index: 999;
  }

  .scroll-top3 {
    position: fixed;
    right: 20px;
    bottom: 100px;
    z-index: 999;
  }
</style>
