<template>
  <div class="home">
    <el-carousel indicator-position="outside" height="850px">
      <el-carousel-item v-for="item in imgList" :key="imgList.url">
        <img :src="item.url" alt=""/>
      </el-carousel-item>
    </el-carousel>
    <div style="margin-top: 2.5%;width: 100%;height: 700px;">
      <!--      <h2 class="ct1">让您的产品更具吸引力</h2><span>Make your products more attractive</span>-->
      <img style="width: 100%;height: 100%" src="../assets/home/son1.jpg">
    </div>
    <div style="margin-top: 2%">
      <h2 class="ct1 ct11 clearfix">
        <span style="color: rgb(84, 92, 100);font-size: 42px;text-align: center;font-weight: bold;">产品中心&nbsp;</span>
        <span style="font-weight: normal;color: #d81617;">|&nbsp;PRODUCT DISPLAY</span>
      </h2>
      <div class="w1">专注产品设计开发制造，是集销售、服务为一体的专业化加工企业</div>
      <div style="margin-left: 34%; width: 31.6%;margin-top: 20px;">
        <a href="#/product" style="text-decoration: none;color: white;">
          <el-button type="info" style="background: rgb(84, 92, 100);height: 50px">半导体零部件</el-button>
        </a>
        <a href="#/product" style="text-decoration: none;color: white;">
          <el-button type="info" style="background: rgb(84, 92, 100);height: 50px">航空医疗器械类</el-button>
        </a>
        <a href="#/product" style="text-decoration: none;color: white;">
          <el-button type="info" style="background: rgb(84, 92, 100);height: 50px">自动化零配件</el-button>
        </a>
        <a href="#/product" style="text-decoration: none;color: white;">
          <el-button type="info" style="background: rgb(84, 92, 100);height: 50px">非标精密零部件</el-button>
        </a>
      </div>
      <div style="margin-top: 25px">
        <img style="width: 380px;height: 380px;" src="../assets/product/a.jpg">
        <img style="width: 380px;height: 380px;margin-left: 40px" src="../assets/product/b.jpg">
        <img style="width: 380px;height: 380px;margin-left: 40px" src="../assets/product/c.jpg">
        <br>
        <img style="width: 380px;height: 380px;margin-top: 25px" src="../assets/product/d.jpg">
        <img style="width: 380px;height: 380px;margin-left: 40px" src="../assets/product/e.jpg">
        <img style="width: 380px;height: 380px;margin-left: 40px" src="../assets/product/f.jpg">
      </div>
    </div>
    <div style="margin-top: 3%;width: 100%;height: 700px;">
      <img style="width: 100%;height: 100%" src="../assets/home/son2.jpg">
    </div>
    <div style="margin-top: 2%">
      <h2 class="ct1 ct11 clearfix">
        <span style="color: rgb(84, 92, 100);font-size: 42px;text-align: center;font-weight: bold;">主要测量设备</span>
      </h2>
      <div style="margin-top: 25px">
        <img style="width: 300px;height: 250px;" src="../assets/equipment/checkoutTool/dzzsq.jpg">
        <img style="width: 300px;height: 250px;margin-left: 30px" src="../assets/equipment/checkoutTool/dzkc.jpg">
        <img style="width: 300px;height: 250px;margin-left: 30px" src="../assets/equipment/checkoutTool/njqfc.jpg">
        <img style="width: 300px;height: 250px;margin-left: 30px" src="../assets/equipment/checkoutTool/kg.jpg">
        <br>
        <img style="width: 300px;height: 250px;margin-top: 20px" src="../assets/equipment/checkoutTool/qfc.jpg">
        <img style="width: 300px;height: 250px;margin-left: 30px" src="../assets/equipment/checkoutTool/qfb.jpg">
        <img style="width: 300px;height: 250px;margin-left: 30px" src="../assets/equipment/checkoutTool/pin.jpg">
        <img style="width: 300px;height: 250px;margin-left: 30px" src="../assets/equipment/checkoutTool/tyy.jpg">
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'

  export default {
    name: 'HomeView',
    components: {
      // HelloWorld
    },
    data() {
      return {
        imgList: [
          {url: require('../assets/home/a.jpg')},
          {url: require('../assets/home/b.jpg')},
          {url: require('../assets/home/c.jpg')}
        ]
      }
    }
  }
</script>
<style lang="scss">
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

  .w1 {
    color: #333333;
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
  }

  .ct1 {
    color: #0e96d4;
    font-size: 42px;
    text-align: center;
    font-weight: bold;
  }

</style>
