import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/goIn',
    name: 'goIn',
    // route level code-splitting
    // this generates a separate chunk (goIn.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "goIn" */ '../views/GoIn.vue')
  },
  {
    path: '/product',
    name: 'product',
    // route level code-splitting
    // this generates a separate chunk (product.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue')
  },
  {
    path: '/equipment',
    name: 'equipment',
    // route level code-splitting
    // this generates a separate chunk (equipment.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "equipment" */ '../views/Equipment.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    // route level code-splitting
    // this generates a separate chunk (recruit.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "recruit" */ '../views/Recruit.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/leaveMessage',
    name: 'leaveMessage',
    // route level code-splitting
    // this generates a separate chunk (leaveMessage.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "leaveMessage" */ '../views/LeaveMessage.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
